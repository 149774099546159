import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Inscription - Solexcursion</title>
            <meta name="description" content="innscription Solexcursion" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Solexcursion 2019</h2>
                    </header>
                    <p>La Solexcursion aura lieu le 9 Juin 2019.
                    <br/>
                    Comme chaque année, afin de garantir votre sécurité, le nombre de solex est limité à 220.
                    </p>
					<p>Les inscriptions ne sont pas encore ouvertes</p>
					<ul className="actions">
                             <li><a href="https://forms.gle/X6JZ34cFhgU1QDas8" className="button">Je m'inscris</a></li>
                            </ul>
				</div>
					
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Pour vous inscrire, vous aurez besoin de:</h3>
                            </header>
							 <div className="grid-wrapper">
                                    <div className="col-4">
                            <p>Un Solex</p>
									</div>
									<div className="col-4">
                            <p>Copie de l'assurance</p>
									</div>
									<div className="col-4">
                            <p>Copie de la carte grise</p>
									</div>
							</div>
							<div className="grid-wrapper">
                                    <div className="col-4">
                            <p>10€</p>
									</div>
									<div className="col-4">
                            <p>De la bonne humeur</p>
									</div>
									<div className="col-4">
                            <p></p>
									</div>
							</div>
                        </div>
						
                    </div>
                </section>
               
            </section>
        </div>

    </Layout>
)

export default Landing
